<template>
  <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="container mx-auto">
      <div class="lg:w-3/6 w-full mx-auto my-10 bg-color p-5 rounded-md shadow-sm">
        <div class="text-center flex justify-center  items-center flex-col">
          <img src="../../../assets/icons/Oonpay logo.png" class="img-fluid bg-white rounded-2xl" height="80px" width="80px" alt="">
          <h3 class="text-oonpaysecondary">
              Crowdfund hub
            </h3>
          <h1
            v-if="step != totalSteps"
            class="my-3 text-3xl font-semibold text-white dark:text-gray-200"
          >
            SignUp
          </h1>
          <ul v-if="errors">
            <li class="text-danger" v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <keep-alive>
          <signup v-if="step == 1"></signup>
          <OTP v-if="step == 2"></OTP>
        </keep-alive>

        <div class="text-center">
          <div class="form-group px-10">
            <button
              @click.prevent="sendCode"
              class="btn btn-primary btn-block mr-5"
              v-if="step != 1"
            >
              Send Code
            </button>
            <button
              @click.prevent="nextStep"
              class="btn btn-primary btn-block "
              v-if="step != totalSteps"
            >
              Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OTP from "./OTP.vue";
import Signup from "./SignupForm.vue";
export default {
  components: { Signup, OTP },
  data() {
    return {
      step: 1,
      totalSteps: 2,
      errors: [],
      appName: "Multi-Step Multi-Component Form",
    };
  },
  methods: {
    sendCode: function () {
      alert("you clicked me");
      this.$router.push('/confirm-otp')
    },
    nextStep: function () {
      this.errors = [];
      if (this.step == 1) {
        if (!this.$store.state.crowdhubaccount.UserFirstName) {
          this.errors.push("Please enter your your first name");
          return false;
        }

        if (!this.$store.state.crowdhubaccount.UserLastName) {
          this.errors.push("Please enter your last name");
          return false;
        }

        if (!this.$store.state.crowdhubaccount.CrowdEmail) {
          this.errors.push("Please enter your email");
          return false;
        }
      }
      // if (this.step == 2) {

      // }
      // if (this.step == 3) {
      // }
      this.step++;
    },
  },
};
</script>
<style  scoped>
.bg-color{
  background: #0c4369 !important;
}

label{
  color: #fff;
}

.btn-primary {
  background: #f15825 !important;
  border: #f15825;
}

.btn-secondary {
  background: #0c4369 !important;
  border: #0c4369;
}
</style>