<template>
  <div class="m-7">
    <form>
      <input type="hidden" name="apikey" value="YOUR_ACCESS_KEY_HERE" />
      <input
        type="hidden"
        name="subject"
        value="New Submission from Web3Forms"
      />
      <input type="checkbox" name="botcheck" id="" style="display: none" />

      <div class="mb-6">
        <label
          for="name"
          class="block mb-2 text-sm text-white dark:text-gray-400"
          >First Name</label
        >
        <input
          type="text"
          name="name"
          @change="updateUserFirstName"
          id="name"
          v-model="userFirstName"
          placeholder="John"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border border-gray-300
            rounded-md
            focus:outline-none
            focus:ring focus:ring-indigo-100
            focus:border-indigo-300
            dark:bg-gray-700
            dark:text-white
            dark:placeholder-gray-500
            dark:border-gray-600
            dark:focus:ring-gray-900
            dark:focus:border-gray-500
          "
        />
      </div>

      <div class="mb-6">
        <label
          for="name"
          class="block mb-2 text-sm text-white dark:text-gray-400"
          >Last Name</label
        >
        <input
          type="text"
          name="name"
          @change="updateLastName"
          id="name"
          v-model="userLastName"
          placeholder="Doe"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border border-gray-300
            rounded-md
            focus:outline-none
            focus:ring focus:ring-indigo-100
            focus:border-indigo-300
            dark:bg-gray-700
            dark:text-white
            dark:placeholder-gray-500
            dark:border-gray-600
            dark:focus:ring-gray-900
            dark:focus:border-gray-500
          "
        />
      </div>
      <div class="mb-6">
        <label
          for="email"
          class="block mb-2 text-sm text-white dark:text-gray-400"
          >Email Address</label
        >
        <input
          type="email"
          name="email"
          id="email"
          @change="updateEmail"
          v-model="CroundEmail"
          placeholder="you@gmail.com"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border border-gray-300
            rounded-md
            focus:outline-none
            focus:ring focus:ring-indigo-100
            focus:border-indigo-300
            dark:bg-gray-700
            dark:text-white
            dark:placeholder-gray-500
            dark:border-gray-600
            dark:focus:ring-gray-900
            dark:focus:border-gray-500
          "
        />
      </div>
      <div class="mb-6">
        <label for="phone" class="text-sm text-white dark:text-gray-400"
          >Password</label
        >
        <input
          type="password"
          name="password"
          id="phone"
          @change="updatePassword"
          placeholder="**********"
          required
          v-model="password"
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border border-gray-300
            rounded-md
            focus:outline-none
            focus:ring focus:ring-indigo-100
            focus:border-indigo-300
            dark:bg-gray-700
            dark:text-white
            dark:placeholder-gray-500
            dark:border-gray-600
            dark:focus:ring-gray-900
            dark:focus:border-gray-500
          "
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userFirstName: "",
      userLastName: "",
      CroundEmail: "",
      password: "",
    };
  },
  methods: {
    updateUserFirstName: function () {
      this.$store.dispatch("crowdhubaccount/setUserFirstName", this.userFirstName);
    },
    updateLastName: function () {
      this.$store.dispatch("crowdhubaccount/setUserLastName", this.userLastName);
    },
    updateEmail: function () {
      this.$store.dispatch("crowdhubaccount/setCrowdEmail", this.CroundEmail);
    },
    updatePassword: function () {
      this.$store.dispatch("crowdhubaccount/setCrowdPassword", this.password);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>