<template>
  <div class="m-7 font-montserrat text-white">
    <h2 class="text-white text-4xl">Keep your account safe</h2>
    <p class="text-lg">
      Add your phone number for another layer of security. We will never share
      your number with anyone else.
    </p>
    <vue-tel-input v-model="phone"></vue-tel-input>
    <p class="text-lg">How should we send the verification code?</p>
  </div>
</template>

<script>
export default {
  two: "Step-Two",
};
</script>

<style lang="scss" scoped></style>
